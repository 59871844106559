export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
};

export type AddNewMaterialPricingGraphqlInput = {
  price: Scalars['Float']['input'];
  recyclerId: Scalars['ID']['input'];
  source: PricingSourceType;
  standardMaterialId: Scalars['ID']['input'];
  targetProducerId?: InputMaybe<Scalars['ID']['input']>;
  updateFrequency: UpdateFrequencyGraphql;
  vat: Scalars['Float']['input'];
};

export type AddressGraphql = {
  __typename?: 'AddressGraphql';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
};

export enum AscDescGraphql {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type AttachmentGraphql = {
  __typename?: 'AttachmentGraphql';
  file_name: Scalars['String']['output'];
  msg: Scalars['String']['output'];
  signed_url: Scalars['String']['output'];
  size: Scalars['String']['output'];
};

export type AvailableCategories = {
  __typename?: 'AvailableCategories';
  category: Scalars['String']['output'];
  subcategories: Array<Maybe<Scalars['String']['output']>>;
};

export type BsdCollection = {
  __typename?: 'BSDCollection';
  collection: Array<Maybe<BsdGraphql>>;
  count: Scalars['Float']['output'];
};

export type BsdGraphql = {
  __typename?: 'BSDGraphql';
  id: Scalars['ID']['output'];
  state: CollectBsdState;
  tdState: TrackdechetsBsdState;
};

export type Co2Saved = {
  __typename?: 'CO2Saved';
  totalCO2Saved: Scalars['Float']['output'];
};

export type CancelCollectInputGraphql = {
  collectId: Scalars['ID']['input'];
};

export type ClientAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  line1?: InputMaybe<Scalars['String']['input']>;
  line2?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
};

export type ClientCollection = {
  __typename?: 'ClientCollection';
  collection: Array<Maybe<ClientGraphql>>;
  count: Scalars['Float']['output'];
};

export type ClientCustomDashboardInputGraphql = {
  dark?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClientDocumentGraphql = StoredDocumentGraphql & {
  __typename?: 'ClientDocumentGraphql';
  client: ClientGraphql;
  createdAt: Scalars['DateTime']['output'];
  extension: Scalars['String']['output'];
  externalReference: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
  type: DocumentType;
};

export type ClientFilterGraphql = {
  clientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Uses a full text search on all clients */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ClientGraphql = {
  address?: Maybe<AddressGraphql>;
  autosignEnabledForTrackdechets: Scalars['Boolean']['output'];
  bic?: Maybe<Scalars['String']['output']>;
  contactFirstName?: Maybe<Scalars['String']['output']>;
  contactLastName?: Maybe<Scalars['String']['output']>;
  customDashboard?: Maybe<Scalars['String']['output']>;
  customDashboardId?: Maybe<Scalars['Float']['output']>;
  documents: StoredDocumentCollection;
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isConnectedToTrackdechets?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  paysMonthlyFees?: Maybe<Scalars['Float']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  siret?: Maybe<Scalars['String']['output']>;
  sites: SitesCollection;
  slug: Scalars['String']['output'];
  type: ClientType;
};


export type ClientGraphqlCustomDashboardArgs = {
  input?: InputMaybe<ClientCustomDashboardInputGraphql>;
};


export type ClientGraphqlDocumentsArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};


export type ClientGraphqlSitesArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};

export type ClientInput = {
  bic?: InputMaybe<Scalars['String']['input']>;
  clientAddress?: InputMaybe<ClientAddressInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paysMonthlyFees?: InputMaybe<Scalars['Float']['input']>;
  percentageRecyclerRevshare?: InputMaybe<Scalars['Float']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  siret?: InputMaybe<Scalars['String']['input']>;
  transporterReceipt?: InputMaybe<Scalars['String']['input']>;
};

export enum ClientType {
  Producer = 'producer',
  Recycler = 'recycler'
}

export enum CollectBsdState {
  Signed = 'SIGNED',
  ToSignByWp = 'TO_SIGN_BY_WP',
  ToSignByWr = 'TO_SIGN_BY_WR'
}

export type CollectCsvExtractGraphql = {
  __typename?: 'CollectCSVExtractGraphql';
  B64Csv: Scalars['String']['output'];
};

export type CollectCsvExtractInput = {
  fromDate: Scalars['DateTime']['input'];
  toDate: Scalars['DateTime']['input'];
};

export type CollectDocumentFilters = {
  clientId?: InputMaybe<Scalars['Float']['input']>;
  collectDateEnd?: InputMaybe<Scalars['DateTime']['input']>;
  collectDateStart?: InputMaybe<Scalars['DateTime']['input']>;
  collectId?: InputMaybe<Scalars['Float']['input']>;
  collectStatuses?: InputMaybe<Array<CollectStatus>>;
  documentMetaSubtypes?: InputMaybe<Array<DocumentMetaSubtypes>>;
  documentName?: InputMaybe<Scalars['String']['input']>;
};

export type CollectDocumentGraphql = StoredDocumentGraphql & {
  __typename?: 'CollectDocumentGraphql';
  client: ClientGraphql;
  collect: CollectGraphql;
  createdAt: Scalars['DateTime']['output'];
  extension: Scalars['String']['output'];
  externalReference: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
  /** if provided by the user, the sub-category of document */
  subtype?: Maybe<DocumentMetaSubtypes>;
  type: DocumentType;
};

export type CollectGraphql = {
  __typename?: 'CollectGraphql';
  /** Related Trackdechets BSDs */
  BSDs: BsdCollection;
  /** The Waste Producer of the collect */
  client: ClientGraphql;
  co2Amount?: Maybe<Scalars['Float']['output']>;
  /** The containers that are handled in this collect */
  collectedContainers: CollectedContainerCollection;
  createdAt: Scalars['DateTime']['output'];
  /** The end destination of the collect. */
  destination?: Maybe<SiteGraphql>;
  documents: StoredDocumentCollection;
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Related incidents */
  incidents: IncidentCollection;
  isInvoiceable: CollectInvoiceGraphql;
  licensePlate?: Maybe<Scalars['String']['output']>;
  /** The location for the pickup of the collect. */
  location: SiteGraphql;
  notes?: Maybe<Scalars['String']['output']>;
  npsRating?: Maybe<NpsGraphql>;
  pricingSummary?: Maybe<CollectPricingSummaryGraphql>;
  /** Once assigned, the set recycler of the collect. */
  recycler?: Maybe<RecyclerGraphql>;
  referent: PartialUserGraphql;
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  status: CollectStatus;
  transportPrice?: Maybe<Scalars['Float']['output']>;
};


export type CollectGraphqlDocumentsArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};


export type CollectGraphqlPricingSummaryArgs = {
  input?: InputMaybe<PricingSummaryInputGraphql>;
};

export type CollectGraphqlFilters = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderBy?: InputMaybe<OrderByGraphql>;
  producerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  recyclerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<CollectStatus>>;
};

export type CollectInvoiceGraphql = {
  __typename?: 'CollectInvoiceGraphql';
  alreadyInvoiced: Scalars['Boolean']['output'];
  errors: Array<Maybe<Scalars['String']['output']>>;
  result: Scalars['Boolean']['output'];
};

export type CollectPricingSummaryDetailsGraphql = {
  __typename?: 'CollectPricingSummaryDetailsGraphql';
  incidentsTotalWithTax: Scalars['Float']['output'];
  incidentsTotalWithoutTax: Scalars['Float']['output'];
  totalResaleWithTax: Scalars['Float']['output'];
  totalResaleWithoutTax: Scalars['Float']['output'];
  totalTax: Scalars['Float']['output'];
  totalTreatmentWithTax: Scalars['Float']['output'];
  totalTreatmentWithoutTax: Scalars['Float']['output'];
  totalWithTax: Scalars['Float']['output'];
  totalWithoutTax: Scalars['Float']['output'];
  transportTotalWithTax: Scalars['Float']['output'];
  transportTotalWithoutTax: Scalars['Float']['output'];
};

export type CollectPricingSummaryGraphql = {
  __typename?: 'CollectPricingSummaryGraphql';
  ready: Scalars['Boolean']['output'];
  summary?: Maybe<CollectPricingSummaryDetailsGraphql>;
};

export enum CollectStatus {
  AdminInProgress = 'adminInProgress',
  Cancelled = 'cancelled',
  Confirmed = 'confirmed',
  Finished = 'finished',
  HasIncident = 'hasIncident',
  InTreatment = 'inTreatment',
  Invoicing = 'invoicing',
  PickupConfirmed = 'pickupConfirmed',
  RequestSent = 'requestSent',
  WpCancel = 'wpCancel',
  WrCancel = 'wrCancel'
}

export type CollectedContainerCollection = {
  __typename?: 'CollectedContainerCollection';
  collection: Array<Maybe<CollectedContainerGraphql>>;
  count: Scalars['Float']['output'];
};

export type CollectedContainerDocumentGraphql = StoredDocumentGraphql & {
  __typename?: 'CollectedContainerDocumentGraphql';
  client: ClientGraphql;
  collectedContainer: CollectedContainerGraphql;
  createdAt: Scalars['DateTime']['output'];
  extension: Scalars['String']['output'];
  externalReference: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
  subtype?: Maybe<DocumentMetaSubtypes>;
  type: DocumentType;
};

export type CollectedContainerGraphql = {
  __typename?: 'CollectedContainerGraphql';
  BSD?: Maybe<BsdGraphql>;
  collect: CollectGraphql;
  container: ContainerGraphql;
  documents: StoredDocumentCollection;
  fillRate?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  material: MaterialGraphql;
  pricingSummary: CollectPricingSummaryGraphql;
  quantity?: Maybe<Scalars['Float']['output']>;
  treatmentCode?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};


export type CollectedContainerGraphqlDocumentsArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};


export type CollectedContainerGraphqlPricingSummaryArgs = {
  input?: InputMaybe<PricingSummaryInputGraphql>;
};

export type CollectedContainerGraphqlFilters = {
  collectIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type CollectsCollection = {
  __typename?: 'CollectsCollection';
  collection: Array<Maybe<CollectGraphql>>;
  count: Scalars['Float']['output'];
};

export type CompanySearchResultGraphql = {
  __typename?: 'CompanySearchResultGraphql';
  address?: Maybe<Scalars['String']['output']>;
  allowBSDASRITakeOverWithoutSignature?: Maybe<Scalars['Boolean']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  facility?: Maybe<FacilityGraphql>;
  isRegistered?: Maybe<Scalars['Boolean']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  naf?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  siret?: Maybe<Scalars['String']['output']>;
  transporterReceipt?: Maybe<TransporterReceiptGraphql>;
};

export type ComplianceBasicNodeGraphql = ComplianceNodeGraphql & {
  __typename?: 'ComplianceBasicNodeGraphql';
  category: Scalars['String']['output'];
  document?: Maybe<ClientDocumentGraphql>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  notes: Scalars['String']['output'];
  state?: Maybe<ComplianceNodeState>;
  value: Scalars['Boolean']['output'];
};

export type ComplianceDocumentNodeGraphql = ComplianceNodeGraphql & {
  __typename?: 'ComplianceDocumentNodeGraphql';
  category: Scalars['String']['output'];
  document: ClientDocumentGraphql;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  notes: Scalars['String']['output'];
  state?: Maybe<ComplianceNodeState>;
  value: Scalars['Boolean']['output'];
};

export type ComplianceLinkNodeGraphql = ComplianceNodeGraphql & {
  __typename?: 'ComplianceLinkNodeGraphql';
  category: Scalars['String']['output'];
  document?: Maybe<ClientDocumentGraphql>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  link: Scalars['String']['output'];
  notes: Scalars['String']['output'];
  state?: Maybe<ComplianceNodeState>;
  value: Scalars['Boolean']['output'];
};

export type ComplianceNodeGraphql = {
  category: Scalars['String']['output'];
  document?: Maybe<ClientDocumentGraphql>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  notes: Scalars['String']['output'];
  state?: Maybe<ComplianceNodeState>;
  value: Scalars['Boolean']['output'];
};

export type ComplianceNodeGraphqlFilter = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
};

export type ComplianceNodeGraphqlInput = {
  documentId?: InputMaybe<Scalars['ID']['input']>;
  externalLink?: InputMaybe<Scalars['String']['input']>;
  isDone?: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  pageNodeId: Scalars['ID']['input'];
  state?: InputMaybe<ComplianceNodeState>;
};

export type ComplianceNodeGraphqlInputList = {
  clientId: Scalars['ID']['input'];
  nodes: Array<ComplianceNodeGraphqlInput>;
};

export enum ComplianceNodeState {
  NonApplicable = 'NON_APPLICABLE',
  NotRequired = 'NOT_REQUIRED',
  Required = 'REQUIRED'
}

export type ComplianceScoreGraphql = {
  __typename?: 'ComplianceScoreGraphql';
  category: Scalars['String']['output'];
  completedRequired: Scalars['Float']['output'];
  score: Scalars['Float']['output'];
  totalRequired: Scalars['Float']['output'];
};

export type ComplianceScoreGraphqlFilter = {
  clientId: Scalars['ID']['input'];
};

export type ComplianceStructureNodeGraphql = {
  __typename?: 'ComplianceStructureNodeGraphql';
  category: Scalars['String']['output'];
  hasDocumentLink: Scalars['Boolean']['output'];
  hasExternalPageLink: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
};

export type ContainerCollection = {
  __typename?: 'ContainerCollection';
  collection: Array<Maybe<ContainerGraphql>>;
  count: Scalars['Float']['output'];
};

export type ContainerDocumentGraphql = StoredDocumentGraphql & {
  __typename?: 'ContainerDocumentGraphql';
  client: ClientGraphql;
  createdAt: Scalars['DateTime']['output'];
  extension: Scalars['String']['output'];
  externalReference: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
  type: DocumentType;
};

export type ContainerFiltersGraphql = {
  clientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  containerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  materialIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ContainerGraphql = {
  __typename?: 'ContainerGraphql';
  documents: StoredDocumentCollection;
  id: Scalars['ID']['output'];
  material: MaterialGraphql;
  name?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  site?: Maybe<SiteGraphql>;
  type?: Maybe<ContainerType>;
  unit?: Maybe<Scalars['String']['output']>;
};


export type ContainerGraphqlDocumentsArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};

export enum ContainerType {
  Ibc = 'IBC',
  Barrel = 'barrel',
  Bin = 'bin',
  Bulk = 'bulk',
  Dumpster = 'dumpster',
  Other = 'other',
  PalletBox = 'pallet_box'
}

export type CreateBsdInput = {
  collectId: Scalars['ID']['input'];
  collectedContainerIds: Array<Scalars['ID']['input']>;
};

export type CreateCapabilityInputGraphql = {
  address?: InputMaybe<Scalars['String']['input']>;
  capCode?: InputMaybe<Scalars['String']['input']>;
  capExpiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  clientId?: InputMaybe<Scalars['ID']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  outletName?: InputMaybe<Scalars['String']['input']>;
  siret?: InputMaybe<Scalars['String']['input']>;
  standardMaterialId: Scalars['ID']['input'];
  treatmentCode: Scalars['String']['input'];
};

export type CreateClientInput = {
  bic?: InputMaybe<Scalars['String']['input']>;
  clientAddress?: InputMaybe<ClientAddressInput>;
  firstName: Scalars['String']['input'];
  iban?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  paysMonthlyFees?: InputMaybe<Scalars['Float']['input']>;
  percentageRecyclerRevshare?: InputMaybe<Scalars['Float']['input']>;
  phoneNumber: Scalars['String']['input'];
  siret: Scalars['String']['input'];
  transporterReceipt?: InputMaybe<Scalars['String']['input']>;
  type: ClientType;
};

export type CreateCollectContainerItemGraphql = {
  containerId: Scalars['ID']['input'];
  materialId: Scalars['ID']['input'];
};

export type CreateCollectGraphql = {
  /** Admin only. Allows creating a collect for a given clientId */
  clientId?: InputMaybe<Scalars['ID']['input']>;
  containers: Array<CreateCollectContainerItemGraphql>;
  endsAt: Scalars['DateTime']['input'];
  notes: Scalars['String']['input'];
  /** The user ID of the owner for this collect. */
  referentUserId: Scalars['ID']['input'];
  /** The site on which the collect should happen. */
  siteId: Scalars['ID']['input'];
  startsAt: Scalars['DateTime']['input'];
};

export type CreateInvoicedServiceInputGraphql = {
  description: Scalars['String']['input'];
  endsAt?: InputMaybe<Scalars['DateTime']['input']>;
  isRecurring: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  producerId: Scalars['ID']['input'];
  recyclerId: Scalars['ID']['input'];
  startsAt: Scalars['DateTime']['input'];
  value: Scalars['Float']['input'];
  vat: Scalars['Float']['input'];
};

export type CreateMaterialInputGraphql = {
  capCode?: InputMaybe<Scalars['String']['input']>;
  clientId: Scalars['ID']['input'];
  consistency: MaterialConsistence;
  isSold?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  price?: InputMaybe<Scalars['Float']['input']>;
  recyclerId: Scalars['ID']['input'];
  standardMaterialId?: InputMaybe<Scalars['ID']['input']>;
  wasteCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePricingInputGraphql = {
  endDate: Scalars['DateTime']['input'];
  makePricingActive?: InputMaybe<Scalars['Boolean']['input']>;
  materialId: Scalars['Float']['input'];
  price: Scalars['Float']['input'];
  producerId: Scalars['Float']['input'];
  recyclerId: Scalars['Float']['input'];
  startDate: Scalars['DateTime']['input'];
  status: PriceListStatus;
  type: PriceListType;
  unit: Scalars['String']['input'];
  vat: Scalars['Float']['input'];
};

export type CreateUserInput = {
  clientId: Scalars['ID']['input'];
  disabled: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  function: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  role: UserRoles;
};

export type CreatedBsdGraphql = {
  __typename?: 'CreatedBSDGraphql';
  collectId: Scalars['ID']['output'];
};

export type CurrentUserGraphql = {
  __typename?: 'CurrentUserGraphql';
  client: ClientGraphql;
  user: UserGraphql;
};

export type DeclareIncidentGraphqlInput = {
  collectId: Scalars['ID']['input'];
  comment: Scalars['String']['input'];
  penaltyAmount: Scalars['Float']['input'];
  penaltyComment: Scalars['String']['input'];
  type: IncidentType;
};

export type DefaultPricingGraphql = MaterialPricingGraphql & {
  __typename?: 'DefaultPricingGraphql';
  correlationId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  details: MaterialPricingDetails;
  id: Scalars['ID']['output'];
  price: Scalars['Float']['output'];
  recycler?: Maybe<ProducerGraphql>;
  shouldBeUpdatedAt: Scalars['DateTime']['output'];
  standardMaterial?: Maybe<StandardMaterialGraphql>;
  targetProducer?: Maybe<ProducerGraphql>;
  updateFrequency: FrequencyGraphql;
  vat: Scalars['Float']['output'];
};

export type DeleteCapabilityGraphql = {
  id: Scalars['ID']['input'];
};

export type DeleteContainerGraphql = {
  id: Scalars['ID']['input'];
};

export type DeleteInvoicedServiceInputGraphql = {
  invoicedServiceId: Scalars['ID']['input'];
};

export type DeleteMaterialInputGraphql = {
  materialId: Scalars['ID']['input'];
};

export type DeleteStoredDocumentGraphqlInput = {
  clientId: Scalars['ID']['input'];
  id: Scalars['String']['input'];
};

export type DeleteUserInput = {
  id: Scalars['ID']['input'];
};

export enum DocumentContext {
  Client = 'CLIENT',
  Collect = 'COLLECT',
  CollectedContainer = 'COLLECTED_CONTAINER',
  Container = 'CONTAINER',
  Incident = 'INCIDENT',
  Material = 'MATERIAL'
}

export type DocumentGraphql = {
  __typename?: 'DocumentGraphql';
  content: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum DocumentMetaSubtypes {
  Co2Report = 'co2_report',
  DestructionCertificate = 'destruction_certificate',
  IncidentReport = 'incident_report',
  Invoice = 'invoice',
  PurchaseOrder = 'purchase_order',
  TdBsd = 'td_bsd',
  TrackingSheet = 'tracking_sheet',
  Uncategorized = 'uncategorized',
  WeightingTicket = 'weighting_ticket'
}

export enum DocumentType {
  Document = 'DOCUMENT',
  Logo = 'LOGO',
  Picture = 'PICTURE'
}

export type FacilityGraphql = {
  __typename?: 'FacilityGraphql';
  rubrics?: Maybe<Array<FacilityRubricGraphql>>;
};

export type FacilityRubricGraphql = {
  __typename?: 'FacilityRubricGraphql';
  activity?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  heading?: Maybe<Scalars['String']['output']>;
  regime?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subheading?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  volume?: Maybe<Scalars['String']['output']>;
  wasteType?: Maybe<WasteType>;
};

export type FrequencyGraphql = {
  __typename?: 'FrequencyGraphql';
  unit: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type GenerateSignedUploadUrlInput = {
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

export type GenerateWasteRegisterInput = {
  fromDate: Scalars['DateTime']['input'];
  toDate: Scalars['DateTime']['input'];
};

export type GeneratedSignedUploadUrl = {
  __typename?: 'GeneratedSignedUploadUrl';
  url: Scalars['String']['output'];
};

export type IncidentCollection = {
  __typename?: 'IncidentCollection';
  collection: Array<Maybe<IncidentGraphql>>;
  count: Scalars['Float']['output'];
};

export type IncidentDocumentGraphql = StoredDocumentGraphql & {
  __typename?: 'IncidentDocumentGraphql';
  client: ClientGraphql;
  createdAt: Scalars['DateTime']['output'];
  extension: Scalars['String']['output'];
  externalReference: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
  type: DocumentType;
};

export type IncidentFiltersGraphql = {
  clientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type IncidentGraphql = {
  __typename?: 'IncidentGraphql';
  attachments?: Maybe<Array<AttachmentGraphql>>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdByUser: UserGraphql;
  documents: StoredDocumentCollection;
  id: Scalars['ID']['output'];
  penaltyAmount?: Maybe<Scalars['Float']['output']>;
  penaltyComment?: Maybe<Scalars['String']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
  status: IncidentStatus;
  type: IncidentType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type IncidentGraphqlDocumentsArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};

export enum IncidentStatus {
  Closed = 'CLOSED',
  Draft = 'DRAFT',
  New = 'NEW',
  Open = 'OPEN'
}

export enum IncidentType {
  AccessProblem = 'ACCESS_PROBLEM',
  MaterialNonConformity = 'MATERIAL_NON_CONFORMITY',
  MaterialUnavailability = 'MATERIAL_UNAVAILABILITY',
  Other = 'OTHER'
}

export type Installation = {
  __typename?: 'Installation';
  rubriques: Array<Rubric>;
};

export type InvoicedServiceCollection = {
  __typename?: 'InvoicedServiceCollection';
  collection: Array<Maybe<InvoicedServiceGraphql>>;
  count: Scalars['Float']['output'];
};

export type InvoicedServiceGraphql = {
  __typename?: 'InvoicedServiceGraphql';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isRecurring: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  producer: ProducerGraphql;
  recycler: RecyclerGraphql;
  startsAt: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
  vat: Scalars['Float']['output'];
};

export type InvoicedServiceInputGraphql = {
  description?: InputMaybe<Scalars['String']['input']>;
  endsAt?: InputMaybe<Scalars['DateTime']['input']>;
  invoicedServiceId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  vat?: InputMaybe<Scalars['Float']['input']>;
};

export type InvoicedServicesGraphqlFilter = {
  /** Filters by both producer and recycler */
  clientId?: InputMaybe<Scalars['ID']['input']>;
};

export type LegacyDataImportInput = {
  forClientId: Scalars['ID']['input'];
  forceFullResync?: InputMaybe<Scalars['Boolean']['input']>;
  pageName: Scalars['String']['input'];
  spreadsheetURI: Scalars['String']['input'];
};

export type LogAsInput = {
  userId: Scalars['String']['input'];
};

export type LoggedAs = {
  __typename?: 'LoggedAs';
  userJWT: Scalars['String']['output'];
};

export type MaterialCollection = {
  __typename?: 'MaterialCollection';
  collection: Array<Maybe<MaterialGraphql>>;
  count: Scalars['Float']['output'];
};

/** The consistency of the material */
export enum MaterialConsistence {
  Doughy = 'DOUGHY',
  Gaseous = 'GASEOUS',
  Liquid = 'LIQUID',
  Solid = 'SOLID'
}

export type MaterialDocumentGraphql = StoredDocumentGraphql & {
  __typename?: 'MaterialDocumentGraphql';
  client: ClientGraphql;
  createdAt: Scalars['DateTime']['output'];
  extension: Scalars['String']['output'];
  externalReference: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
  type: DocumentType;
};

export type MaterialFiltersGraphql = {
  clientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  hasContainer?: InputMaybe<Scalars['Boolean']['input']>;
  hasStandardMaterial?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type MaterialGraphql = {
  __typename?: 'MaterialGraphql';
  /** @deprecated use new pricing system instead */
  activePricing?: Maybe<PricingGraphql>;
  capCode?: Maybe<Scalars['String']['output']>;
  consistency: MaterialConsistence;
  container: Array<ContainerGraphql>;
  documents: StoredDocumentCollection;
  id: Scalars['ID']['output'];
  isSold?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  /** @deprecated use new pricing system instead */
  previousPricings?: Maybe<PricingCollection>;
  price?: Maybe<Scalars['Float']['output']>;
  producer: ProducerGraphql;
  recycler?: Maybe<RecyclerGraphql>;
  standardMaterial: StandardMaterialGraphql;
  wasteCode?: Maybe<Scalars['String']['output']>;
};


export type MaterialGraphqlDocumentsArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};

export type MaterialPricingCollection = {
  __typename?: 'MaterialPricingCollection';
  collection: Array<Maybe<MaterialPricingGraphql>>;
  count: Scalars['Float']['output'];
};

export type MaterialPricingDetails = {
  __typename?: 'MaterialPricingDetails';
  VATAmount: Scalars['Float']['output'];
  totalRevshareEugene?: Maybe<Scalars['Float']['output']>;
  totalWithVAT: Scalars['Float']['output'];
  totalWithoutVAT: Scalars['Float']['output'];
};

export type MaterialPricingGraphql = {
  correlationId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  details: MaterialPricingDetails;
  id: Scalars['ID']['output'];
  price: Scalars['Float']['output'];
  recycler?: Maybe<ProducerGraphql>;
  shouldBeUpdatedAt: Scalars['DateTime']['output'];
  standardMaterial?: Maybe<StandardMaterialGraphql>;
  targetProducer?: Maybe<ProducerGraphql>;
  updateFrequency: FrequencyGraphql;
  vat: Scalars['Float']['output'];
};

export type MaterialPricingGraphqlFilters = {
  materialCategories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  materialSubcategories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  producerId?: InputMaybe<Scalars['ID']['input']>;
  recyclerId?: InputMaybe<Scalars['ID']['input']>;
};

export type MaterialPricingPerMaterialCollection = {
  __typename?: 'MaterialPricingPerMaterialCollection';
  collection: Array<Maybe<MaterialPricingPerMaterialGraphql>>;
  count: Scalars['Float']['output'];
};

export type MaterialPricingPerMaterialGraphql = {
  __typename?: 'MaterialPricingPerMaterialGraphql';
  correlationId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  details: MaterialPricingDetails;
  id: Scalars['ID']['output'];
  price: Scalars['Float']['output'];
  producerPricings: MaterialPricingCollection;
  recycler?: Maybe<ProducerGraphql>;
  shouldBeUpdatedAt: Scalars['DateTime']['output'];
  standardMaterial?: Maybe<StandardMaterialGraphql>;
  targetProducer?: Maybe<ProducerGraphql>;
  updateFrequency: FrequencyGraphql;
  vat: Scalars['Float']['output'];
};


export type MaterialPricingPerMaterialGraphqlProducerPricingsArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};

export type MaterialQuantity = {
  __typename?: 'MaterialQuantity';
  materialName?: Maybe<Scalars['String']['output']>;
  materialQuantity?: Maybe<Scalars['Float']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Accept CGU */
  acceptCGU: Scalars['Boolean']['output'];
  addCompliancePageData: Array<ComplianceNodeGraphql>;
  addInvoicedService: InvoicedServiceGraphql;
  addNewMaterialPricing: MaterialPricingGraphql;
  addProducersToRecycler: RecyclerGraphql;
  addRecyclerCapability: RecyclerCapabilityGraphql;
  addRecyclersToProducer: ProducerGraphql;
  cancelCollect: CollectGraphql;
  /** Provided that the user is authenticated to Trackdechets and that no BSD already exists for this collect, creates a BSD on Trackdechets */
  createBSDForCollect: CreatedBsdGraphql;
  /** ADMIN ONLY -- Creates a client */
  createClient: ClientGraphql;
  createCollect: CollectGraphql;
  createMaterial: MaterialGraphql;
  createPricing: PricingGraphql;
  /** ADMIN ONLY -- Creates a user */
  createUser: UserGraphql;
  declareIncidentOnCollect: IncidentGraphql;
  deleteCapability: Scalars['Boolean']['output'];
  deleteContainer: Scalars['Boolean']['output'];
  deleteIncident: IncidentGraphql;
  deleteInvoicedService: InvoicedServiceGraphql;
  /** Admin-only -- Soft deletes a material */
  deleteMaterial: MaterialGraphql;
  deleteStoredDocument: Scalars['Boolean']['output'];
  /** ADMIN ONLY -- Deletes a user */
  deleteUser: Scalars['Boolean']['output'];
  generateSignedUploadUrl: GeneratedSignedUploadUrl;
  importLegacyDataSpreadsheet: Scalars['Boolean']['output'];
  importSpreadsheet: Scalars['Boolean']['output'];
  logAs?: Maybe<LoggedAs>;
  rateCollectNPS: CollectGraphql;
  removeProducersFromRecycler: RecyclerGraphql;
  removeRecyclersFromProducer: ProducerGraphql;
  /** Admin only. Send a collect created email for a specific collect. */
  sendCollectCreatedEmail: Scalars['Boolean']['output'];
  signAllCollectBSDs: Array<BsdGraphql>;
  signBSDs: Array<BsdGraphql>;
  storeDocumentReference: StoredDocumentGraphql;
  toggleTrackdechetsAutosign: ClientGraphql;
  updateClient: ClientGraphql;
  updateCollectInformations: CollectGraphql;
  updateCollectedContainer: CollectedContainerGraphql;
  updateIncident: IncidentGraphql;
  updateInvoicedService: InvoicedServiceGraphql;
  updateMaterial: MaterialGraphql;
  /** ADMIN ONLY -- Updates a user */
  updateUser: UserGraphql;
  upsertContainer: ContainerGraphql;
  upsertSite: SiteGraphql;
};


export type MutationAddCompliancePageDataArgs = {
  input: ComplianceNodeGraphqlInputList;
};


export type MutationAddInvoicedServiceArgs = {
  input: CreateInvoicedServiceInputGraphql;
};


export type MutationAddNewMaterialPricingArgs = {
  input: AddNewMaterialPricingGraphqlInput;
};


export type MutationAddProducersToRecyclerArgs = {
  input: ProducersToRecyclerInput;
};


export type MutationAddRecyclerCapabilityArgs = {
  input: CreateCapabilityInputGraphql;
};


export type MutationAddRecyclersToProducerArgs = {
  input: RecyclersToProducerInput;
};


export type MutationCancelCollectArgs = {
  input: CancelCollectInputGraphql;
};


export type MutationCreateBsdForCollectArgs = {
  input: CreateBsdInput;
};


export type MutationCreateClientArgs = {
  input: CreateClientInput;
};


export type MutationCreateCollectArgs = {
  input: CreateCollectGraphql;
};


export type MutationCreateMaterialArgs = {
  input: CreateMaterialInputGraphql;
};


export type MutationCreatePricingArgs = {
  input: CreatePricingInputGraphql;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeclareIncidentOnCollectArgs = {
  input: DeclareIncidentGraphqlInput;
};


export type MutationDeleteCapabilityArgs = {
  input: DeleteCapabilityGraphql;
};


export type MutationDeleteContainerArgs = {
  input: DeleteContainerGraphql;
};


export type MutationDeleteIncidentArgs = {
  input: UpdateIncidentGraphqlInput;
};


export type MutationDeleteInvoicedServiceArgs = {
  input: DeleteInvoicedServiceInputGraphql;
};


export type MutationDeleteMaterialArgs = {
  input: DeleteMaterialInputGraphql;
};


export type MutationDeleteStoredDocumentArgs = {
  input: DeleteStoredDocumentGraphqlInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationGenerateSignedUploadUrlArgs = {
  input: GenerateSignedUploadUrlInput;
};


export type MutationImportLegacyDataSpreadsheetArgs = {
  input: LegacyDataImportInput;
};


export type MutationLogAsArgs = {
  input: LogAsInput;
};


export type MutationRateCollectNpsArgs = {
  input: RateCollectNpsInputGraphql;
};


export type MutationRemoveProducersFromRecyclerArgs = {
  input: ProducersToRecyclerInput;
};


export type MutationRemoveRecyclersFromProducerArgs = {
  input: RecyclersToProducerInput;
};


export type MutationSendCollectCreatedEmailArgs = {
  collectId: Scalars['String']['input'];
};


export type MutationSignAllCollectBsDsArgs = {
  input: SignAllCollectBsDsInput;
};


export type MutationSignBsDsArgs = {
  input: SignBsdInput;
};


export type MutationStoreDocumentReferenceArgs = {
  input: StoreDocumentReferenceInput;
};


export type MutationUpdateClientArgs = {
  input: ClientInput;
};


export type MutationUpdateCollectInformationsArgs = {
  input: UpdateCollectInformationsGraphql;
};


export type MutationUpdateCollectedContainerArgs = {
  input: UpdateCollectedContainerInput;
};


export type MutationUpdateIncidentArgs = {
  input: UpdateIncidentGraphqlInput;
};


export type MutationUpdateInvoicedServiceArgs = {
  input: InvoicedServiceInputGraphql;
};


export type MutationUpdateMaterialArgs = {
  input: UpdateMaterialInputGraphql;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpsertContainerArgs = {
  input: UpsertContainerGraphql;
};


export type MutationUpsertSiteArgs = {
  input: SiteInputGraphql;
};

export type NpsGraphql = {
  __typename?: 'NPSGraphql';
  additionalDetails?: Maybe<Scalars['String']['output']>;
  rating: Scalars['Float']['output'];
};

export type OrderByGraphql = {
  field: Scalars['String']['input'];
  order: AscDescGraphql;
};

export type PaginationArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
};

export type PartialUserCollection = {
  __typename?: 'PartialUserCollection';
  collection: Array<Maybe<PartialUserGraphql>>;
  count: Scalars['Float']['output'];
};

export type PartialUserGraphql = {
  __typename?: 'PartialUserGraphql';
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
};

export type PriceListCollection = {
  __typename?: 'PriceListCollection';
  collection: Array<Maybe<PricingGraphql>>;
  count: Scalars['Float']['output'];
};

export enum PriceListStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum PriceListType {
  BinCollection = 'BIN_COLLECTION'
}

export type PricingCollection = {
  __typename?: 'PricingCollection';
  collection: Array<Maybe<PricingGraphql>>;
  count: Scalars['Float']['output'];
};

export type PricingFiltersGraphql = {
  clientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  materialIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  status?: InputMaybe<PriceListStatus>;
};

export type PricingGraphql = {
  __typename?: 'PricingGraphql';
  createdAt: Scalars['DateTime']['output'];
  endDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  material: MaterialGraphql;
  price: Scalars['Float']['output'];
  producer: ProducerGraphql;
  startDate: Scalars['DateTime']['output'];
  status: PriceListStatus;
  unit: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vat: Scalars['Float']['output'];
};

export enum PricingSourceType {
  Manual = 'MANUAL'
}

export type PricingSummaryInputGraphql = {
  viewAsProducer: Scalars['Boolean']['input'];
};

export type ProducerCollection = {
  __typename?: 'ProducerCollection';
  collection: Array<Maybe<ProducerGraphql>>;
  count: Scalars['Float']['output'];
};

export type ProducerGraphql = ClientGraphql & {
  __typename?: 'ProducerGraphql';
  address?: Maybe<AddressGraphql>;
  autosignEnabledForTrackdechets: Scalars['Boolean']['output'];
  bic?: Maybe<Scalars['String']['output']>;
  contactFirstName?: Maybe<Scalars['String']['output']>;
  contactLastName?: Maybe<Scalars['String']['output']>;
  customDashboard?: Maybe<Scalars['String']['output']>;
  customDashboardId?: Maybe<Scalars['Float']['output']>;
  documents: StoredDocumentCollection;
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isConnectedToTrackdechets?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  paysMonthlyFees?: Maybe<Scalars['Float']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  recyclers: RecyclerCollection;
  siret?: Maybe<Scalars['String']['output']>;
  sites: SitesCollection;
  slug: Scalars['String']['output'];
  type: ClientType;
};


export type ProducerGraphqlCustomDashboardArgs = {
  input?: InputMaybe<ClientCustomDashboardInputGraphql>;
};


export type ProducerGraphqlDocumentsArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};


export type ProducerGraphqlRecyclersArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};


export type ProducerGraphqlSitesArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};

export type ProducersToRecyclerInput = {
  producerIds: Array<Scalars['ID']['input']>;
  recyclerId: Scalars['ID']['input'];
};

export type Query = {
  __typename?: 'Query';
  availableCategoriesAndSubcategories: Array<AvailableCategories>;
  clients: ClientCollection;
  co2Saved: Co2Saved;
  collectCSVExtract: CollectCsvExtractGraphql;
  collectDocuments: StoredDocumentCollection;
  collectedContainers: CollectedContainerCollection;
  /** The list of collects for the current user */
  collects: CollectsCollection;
  collectsWithMissingFields: Array<CollectGraphql>;
  compliancePage: Array<ComplianceNodeGraphql>;
  compliancePageStructure: Array<ComplianceStructureNodeGraphql>;
  complianceScore: Array<ComplianceScoreGraphql>;
  containers: ContainerCollection;
  documents: StoredDocumentCollection;
  generateWasteRegister: DocumentGraphql;
  getDashboardUrl: Scalars['String']['output'];
  getWastePerMonthPerType?: Maybe<Array<WastePerMonthPerType>>;
  incidents: IncidentCollection;
  invoicedServices: InvoicedServiceCollection;
  materialPricings: MaterialPricingCollection;
  /** Returns pricings per material without returning the producer pricings. */
  materialPricingsPerMaterial: MaterialPricingPerMaterialCollection;
  materials: MaterialCollection;
  me: CurrentUserGraphql;
  moneyPerMonthMetrics: Array<TotalMoneyPerMonth>;
  pricingsv1: PriceListCollection;
  recyclerCapabilities: RecyclerCapabilityCollection;
  /** Fetches the list of possible referent users for the collect creation. */
  referents: PartialUserCollection;
  searchCompanies: Array<CompanySearchResultGraphql>;
  sites: SitesCollection;
  standardMaterials: StandardMaterialCollection;
  treatmentCodes: Array<TreatmentCodeGraphql>;
  /** ADMIN ONLY -- Fetches all users */
  users: UserCollection;
  wasteCodes: Array<WasteCodesGraphql>;
};


export type QueryClientsArgs = {
  filters: ClientFilterGraphql;
  pagination?: InputMaybe<PaginationArgs>;
};


export type QueryCollectCsvExtractArgs = {
  input: CollectCsvExtractInput;
};


export type QueryCollectDocumentsArgs = {
  filters?: InputMaybe<CollectDocumentFilters>;
  pagination?: InputMaybe<PaginationArgs>;
};


export type QueryCollectedContainersArgs = {
  input?: InputMaybe<CollectedContainerGraphqlFilters>;
  pagination?: InputMaybe<PaginationArgs>;
};


export type QueryCollectsArgs = {
  filters?: InputMaybe<CollectGraphqlFilters>;
  pagination?: InputMaybe<PaginationArgs>;
};


export type QueryCollectsWithMissingFieldsArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};


export type QueryCompliancePageArgs = {
  filter?: InputMaybe<ComplianceNodeGraphqlFilter>;
};


export type QueryComplianceScoreArgs = {
  filter: ComplianceScoreGraphqlFilter;
};


export type QueryContainersArgs = {
  filters?: InputMaybe<ContainerFiltersGraphql>;
  pagination?: InputMaybe<PaginationArgs>;
};


export type QueryDocumentsArgs = {
  filters: StoredDocumentFilters;
  pagination?: InputMaybe<PaginationArgs>;
};


export type QueryGenerateWasteRegisterArgs = {
  input: GenerateWasteRegisterInput;
};


export type QueryGetDashboardUrlArgs = {
  clientId: Scalars['Float']['input'];
};


export type QueryIncidentsArgs = {
  filters?: InputMaybe<IncidentFiltersGraphql>;
  pagination?: InputMaybe<PaginationArgs>;
};


export type QueryInvoicedServicesArgs = {
  filters: InvoicedServicesGraphqlFilter;
  pagination?: InputMaybe<PaginationArgs>;
};


export type QueryMaterialPricingsArgs = {
  filters: MaterialPricingGraphqlFilters;
  pagination?: InputMaybe<PaginationArgs>;
};


export type QueryMaterialPricingsPerMaterialArgs = {
  filters: MaterialPricingGraphqlFilters;
  pagination?: InputMaybe<PaginationArgs>;
};


export type QueryMaterialsArgs = {
  filters: MaterialFiltersGraphql;
  pagination?: InputMaybe<PaginationArgs>;
};


export type QueryPricingsv1Args = {
  filters: PricingFiltersGraphql;
};


export type QueryRecyclerCapabilitiesArgs = {
  filters: RecyclerCapabilityGraphqlFilter;
  pagination?: InputMaybe<PaginationArgs>;
};


export type QuerySearchCompaniesArgs = {
  filters: SearchCompanyFilters;
};


export type QuerySitesArgs = {
  filters: SitesFilterGraphql;
  pagination?: InputMaybe<PaginationArgs>;
};


export type QueryStandardMaterialsArgs = {
  filters: StandardMaterialFilters;
};


export type QueryUsersArgs = {
  filters?: InputMaybe<UserFilterGraphql>;
  pagination?: InputMaybe<PaginationArgs>;
};

export type RateCollectNpsInputGraphql = {
  additionalDetails?: InputMaybe<Scalars['String']['input']>;
  collectId: Scalars['ID']['input'];
  rating: Scalars['Float']['input'];
};

export type RecyclerCapabilityCollection = {
  __typename?: 'RecyclerCapabilityCollection';
  collection: Array<Maybe<RecyclerCapabilityGraphql>>;
  count: Scalars['Float']['output'];
};

export type RecyclerCapabilityGraphql = {
  __typename?: 'RecyclerCapabilityGraphql';
  capCode?: Maybe<Scalars['String']['output']>;
  capExpiresAt?: Maybe<Scalars['DateTime']['output']>;
  client: RecyclerGraphql;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  outlet: RecyclerGraphql;
  outletName?: Maybe<Scalars['String']['output']>;
  standardMaterial: StandardMaterialGraphql;
  treatmentCode: Scalars['String']['output'];
};

export type RecyclerCapabilityGraphqlFilter = {
  /** Admin only. */
  clientId?: InputMaybe<Scalars['ID']['input']>;
};

export type RecyclerCollection = {
  __typename?: 'RecyclerCollection';
  collection: Array<Maybe<RecyclerGraphql>>;
  count: Scalars['Float']['output'];
};

export type RecyclerGraphql = ClientGraphql & {
  __typename?: 'RecyclerGraphql';
  address?: Maybe<AddressGraphql>;
  autosignEnabledForTrackdechets: Scalars['Boolean']['output'];
  bic?: Maybe<Scalars['String']['output']>;
  contactFirstName?: Maybe<Scalars['String']['output']>;
  contactLastName?: Maybe<Scalars['String']['output']>;
  customDashboard?: Maybe<Scalars['String']['output']>;
  customDashboardId?: Maybe<Scalars['Float']['output']>;
  documents: StoredDocumentCollection;
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isConnectedToTrackdechets?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  paysMonthlyFees?: Maybe<Scalars['Float']['output']>;
  percentageRecyclerRevshare?: Maybe<Scalars['Float']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  producers: ProducerCollection;
  siret?: Maybe<Scalars['String']['output']>;
  sites: SitesCollection;
  slug: Scalars['String']['output'];
  transporterReceipt?: Maybe<Scalars['String']['output']>;
  type: ClientType;
};


export type RecyclerGraphqlCustomDashboardArgs = {
  input?: InputMaybe<ClientCustomDashboardInputGraphql>;
};


export type RecyclerGraphqlDocumentsArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};


export type RecyclerGraphqlProducersArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};


export type RecyclerGraphqlSitesArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};

export type RecyclersToProducerInput = {
  producerId: Scalars['ID']['input'];
  recyclerIds: Array<Scalars['ID']['input']>;
};

export type Rubric = {
  __typename?: 'Rubric';
  activite?: Maybe<Scalars['String']['output']>;
  alinea?: Maybe<Scalars['String']['output']>;
  category: Scalars['String']['output'];
  etatActivite?: Maybe<Scalars['String']['output']>;
  regimeAutorise?: Maybe<Scalars['String']['output']>;
  rubrique: Scalars['String']['output'];
  unite?: Maybe<Scalars['String']['output']>;
  volume?: Maybe<Scalars['String']['output']>;
  wasteType?: Maybe<Scalars['String']['output']>;
};

export type SearchCompanyFilters = {
  clue: Scalars['String']['input'];
  department?: InputMaybe<Scalars['String']['input']>;
};

export type SignAllCollectBsDsInput = {
  collectId: Scalars['ID']['input'];
  signatureCode?: InputMaybe<Scalars['Float']['input']>;
};

export type SignBsdInput = {
  collectId: Scalars['ID']['input'];
  collectedContainerIds: Scalars['ID']['input'];
  signatureCode?: InputMaybe<Scalars['Float']['input']>;
};

export type SiteGraphql = {
  __typename?: 'SiteGraphql';
  address?: Maybe<Scalars['String']['output']>;
  client: ClientGraphql;
  containers: ContainerCollection;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type SiteInputGraphql = {
  address?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type SitesCollection = {
  __typename?: 'SitesCollection';
  collection: Array<Maybe<SiteGraphql>>;
  count: Scalars['Float']['output'];
};

export type SitesFilterGraphql = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
  siteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type StandardMaterialCollection = {
  __typename?: 'StandardMaterialCollection';
  collection: Array<Maybe<StandardMaterialGraphql>>;
  count: Scalars['Float']['output'];
};

export type StandardMaterialFilters = {
  search: Scalars['String']['input'];
};

export type StandardMaterialGraphql = {
  __typename?: 'StandardMaterialGraphql';
  attributes: Scalars['String']['output'];
  category: Scalars['String']['output'];
  consistency: MaterialConsistence;
  id: Scalars['ID']['output'];
  isDangerous: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  pricings?: Maybe<MaterialPricingCollection>;
  reference: Scalars['String']['output'];
  shape: Scalars['String']['output'];
  subCategory: Scalars['String']['output'];
};


export type StandardMaterialGraphqlPricingsArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};

export type StoreDocumentReferenceInput = {
  /** Allows an admin to upload a document to a specific client */
  clientId?: InputMaybe<Scalars['ID']['input']>;
  /** The other object this document relates to. */
  externalReference: DocumentContext;
  /** the name of the file with its extension */
  fileName: Scalars['String']['input'];
  /** the unique ID of the object referenced in the externalReference */
  referenceObjectId?: InputMaybe<Scalars['ID']['input']>;
  /** Only for a collect document. The subtype of the document */
  subtype?: InputMaybe<DocumentMetaSubtypes>;
  type: DocumentType;
};

export type StoredDocumentCollection = {
  __typename?: 'StoredDocumentCollection';
  collection: Array<Maybe<StoredDocumentGraphql>>;
  count: Scalars['Float']['output'];
};

export type StoredDocumentFilters = {
  contexts?: InputMaybe<Array<DocumentContext>>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type StoredDocumentGraphql = {
  client: ClientGraphql;
  createdAt: Scalars['DateTime']['output'];
  extension: Scalars['String']['output'];
  externalReference: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
  type: DocumentType;
};

export type TotalMoneyPerMonth = {
  __typename?: 'TotalMoneyPerMonth';
  end: Scalars['DateTime']['output'];
  start: Scalars['DateTime']['output'];
  totalsMetric: TotalsMetric;
};

export type TotalsMetric = {
  __typename?: 'TotalsMetric';
  totalIncidents: Scalars['Float']['output'];
  /** @deprecated Use totalMaterialsEarnings and totalMaterialsCosts instead */
  totalMaterials?: Maybe<Scalars['Float']['output']>;
  totalMaterialsCosts: Scalars['Float']['output'];
  totalMaterialsEarnings: Scalars['Float']['output'];
  totalTransport: Scalars['Float']['output'];
};

export enum TrackdechetsBsdState {
  Accepted = 'ACCEPTED',
  Draft = 'DRAFT',
  Processed = 'PROCESSED',
  Sealed = 'SEALED',
  Sent = 'SENT',
  SignedByProducer = 'SIGNED_BY_PRODUCER'
}

export type TransporterReceipt = {
  __typename?: 'TransporterReceipt';
  department: Scalars['String']['output'];
  id: Scalars['String']['output'];
  receiptNumber: Scalars['String']['output'];
  validityLimit: Scalars['DateTime']['output'];
};

export type TransporterReceiptGraphql = {
  __typename?: 'TransporterReceiptGraphql';
  department?: Maybe<Scalars['String']['output']>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  receiptNumber?: Maybe<Scalars['String']['output']>;
};

export type TreatmentCodeGraphql = {
  __typename?: 'TreatmentCodeGraphql';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  treatmentKind?: Maybe<TreatmentName>;
};

export enum TreatmentName {
  Elimination = 'ELIMINATION',
  None = 'NONE',
  Recyclage = 'RECYCLAGE',
  Reutilisation = 'REUTILISATION',
  ValorisationEnergetique = 'VALORISATION_ENERGETIQUE'
}

export type UpdateCollectInformationsGraphql = {
  co2Amount?: InputMaybe<Scalars['Float']['input']>;
  collectId: Scalars['ID']['input'];
  collectionPrice?: InputMaybe<Scalars['Float']['input']>;
  endsAt?: InputMaybe<Scalars['DateTime']['input']>;
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  referentId?: InputMaybe<Scalars['String']['input']>;
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<CollectStatus>;
  transportPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateCollectedContainerInput = {
  collectedContainerId: Scalars['ID']['input'];
  quantity?: InputMaybe<Scalars['Float']['input']>;
  treatmentCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFrequencyGraphql = {
  unit: Scalars['String']['input'];
  value: Scalars['Float']['input'];
};

export type UpdateIncidentGraphqlInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  incidentId: Scalars['ID']['input'];
  penaltyAmount?: InputMaybe<Scalars['Float']['input']>;
  penaltyComment?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<IncidentStatus>;
  type?: InputMaybe<IncidentType>;
};

export type UpdateMaterialInputGraphql = {
  capCode?: InputMaybe<Scalars['String']['input']>;
  consistency?: InputMaybe<MaterialConsistence>;
  isSold?: InputMaybe<Scalars['Boolean']['input']>;
  materialId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  recyclerId?: InputMaybe<Scalars['ID']['input']>;
  standardMaterialId?: InputMaybe<Scalars['ID']['input']>;
  wasteCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  function?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserRoles>;
};

export type UpsertContainerGraphql = {
  clientId: Scalars['ID']['input'];
  containerType: ContainerType;
  id?: InputMaybe<Scalars['ID']['input']>;
  materialId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  quantity: Scalars['Float']['input'];
  siteId: Scalars['ID']['input'];
  unit?: Scalars['String']['input'];
};

export type UserCollection = {
  __typename?: 'UserCollection';
  collection: Array<Maybe<UserGraphql>>;
  count: Scalars['Float']['output'];
};

export type UserFilterGraphql = {
  clientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Searches all users using text. Overrides all other parameters. */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type UserGraphql = {
  __typename?: 'UserGraphql';
  acceptedTermsAt?: Maybe<Scalars['DateTime']['output']>;
  client: ClientGraphql;
  disabled?: Maybe<Scalars['Boolean']['output']>;
  /** Will sometimes be not provided depending on context as we consider this field to be confidential. */
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  function?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  role: UserRoles;
};

export enum UserRoles {
  AdminApp = 'admin_app',
  User = 'user'
}

export type WpSpecificPricingGraphql = MaterialPricingGraphql & {
  __typename?: 'WPSpecificPricingGraphql';
  correlationId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  details: MaterialPricingDetails;
  id: Scalars['ID']['output'];
  price: Scalars['Float']['output'];
  recycler?: Maybe<ProducerGraphql>;
  shouldBeUpdatedAt: Scalars['DateTime']['output'];
  standardMaterial?: Maybe<StandardMaterialGraphql>;
  targetProducer?: Maybe<ProducerGraphql>;
  updateFrequency: FrequencyGraphql;
  vat: Scalars['Float']['output'];
};

export type WasteCodeGraphql = {
  __typename?: 'WasteCodeGraphql';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

export type WasteCodeSubCategoryGraphql = {
  __typename?: 'WasteCodeSubCategoryGraphql';
  codes: Array<WasteCodeGraphql>;
  subCategoryCode: Scalars['String']['output'];
  subCategoryDescription: Scalars['String']['output'];
};

export type WasteCodesGraphql = {
  __typename?: 'WasteCodesGraphql';
  categoryCode: Scalars['String']['output'];
  categoryDescription: Scalars['String']['output'];
  subCategories: Array<WasteCodeSubCategoryGraphql>;
};

export type WastePerMonthPerType = {
  __typename?: 'WastePerMonthPerType';
  end: Scalars['DateTime']['output'];
  start: Scalars['DateTime']['output'];
  totalQuantityInMonth: Array<MaterialQuantity>;
  unit: Scalars['String']['output'];
};

export enum WasteType {
  Dangerous = 'DANGEROUS',
  Inerte = 'INERTE',
  NotDangerous = 'NOT_DANGEROUS'
}

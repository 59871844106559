import { createFragmentRegistry } from '@apollo/client/cache'
import { ADMIN_FIELDS_FRAGMENT } from './collect-admin-fragment'
import { COLLECT_DETAILS_SUMMARY_FRAGMENT } from './collect-details-summary'

export const fragmentRegistry = createFragmentRegistry()

export function registerFragment() {
  fragmentRegistry.register(ADMIN_FIELDS_FRAGMENT)
  fragmentRegistry.register(COLLECT_DETAILS_SUMMARY_FRAGMENT)
}

<script setup lang="ts">
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from '@floating-ui/vue'

import { v4 as uuid } from 'uuid'

// Simplified interfaces with boolean support
interface Option {
  value: string | number | boolean
  label: string
  disabled?: boolean
}

type OptionType = Option | string | number | boolean
type ModelValueType = string | number | boolean | undefined | (string | number | boolean)[]

const props = withDefaults(defineProps<{
  options: OptionType[]
  btnClass?: string
  btnIcon?: Component
  suffixBtnIcon?: Component
  buttonLabel?: string
  multiple?: boolean
  buttonDisabled?: boolean
  numberOfShownLabels?: number
}>(), {
  options: () => [],
  btnClass: 'btn-primary',
  btnIcon: undefined,
  suffixBtnIcon: undefined,
  buttonLabel: 'Sélectionner une option',
  multiple: false,
  buttonDisabled: false,
  numberOfShownLabels: 2,
})

const emit = defineEmits<{
  change: [value: string | number | boolean | undefined | (string | number | boolean)[]]
}>()

const model = defineModel<ModelValueType>({
  type: [String, Number, Boolean, Array, undefined] as PropType<ModelValueType>,
  default: () => [],
  required: true,
})

// UI state
const isOpen = ref(false)
const reference = ref<HTMLElement | null>(null)
const floating = ref<HTMLElement | null>(null)

// Floating UI setup
const { floatingStyles, update } = useFloating(reference, floating, {
  placement: 'bottom',
  middleware: [offset(10), shift(), flip()],
  whileElementsMounted: autoUpdate,
  open: isOpen,
})

function isOptionAnObject(option: OptionType): option is Option {
  return typeof option === 'object' && option !== null && 'value' in option && 'label' in option
}

// Helper functions
function getModelValue(): (string | number | boolean)[] {
  if (Array.isArray(model.value)) {
    return model.value
  }
  return model.value !== undefined ? [model.value] : []
}

function getOptionLabel(option: OptionType): string {
  return isOptionAnObject(option) ? option.label : String(option)
}

function getOptionValue(option: OptionType): string | number | boolean {
  return isOptionAnObject(option) ? option.value : option
}

function handleChange(option: OptionType): void {
  const value = getOptionValue(option)

  if (props.multiple) {
    const currentValue = getModelValue()
    const index = currentValue.indexOf(value)

    if (index === -1) {
      model.value = [...currentValue, value]
    }
    else {
      const newValue = currentValue.filter(v => v !== value)
      model.value = newValue.length ? newValue : []
    }
  }
  else {
    // For single selection, toggle between value and undefined
    model.value = isSelected(option) ? undefined : value
  }

  emit('change', model.value)

  if (!props.multiple) {
    isOpen.value = false
  }
}

function isSelected(option: OptionType): boolean {
  const optionValue = getOptionValue(option)
  const modelValues = getModelValue()
  return modelValues.some(value =>
    // Handle different types of comparisons
    optionValue === value
    || (typeof optionValue === 'string' && typeof value === 'string' && optionValue.toLowerCase() === value.toLowerCase())
    || String(optionValue) === String(value),
  )
}

function toggle(): void {
  isOpen.value = !isOpen.value
  if (isOpen.value) {
    nextTick(update)
  }
}

function closeDropdown() {
  isOpen.value = false
}
</script>

<template>
  <div>
    <div class="indicator">
      <span
        v-if="multiple && getModelValue().length > 0"
        class="indicator-item badge badge-primary badge-xs text-xs"
      />
      <EButton
        ref="reference"
        v-click-outside="closeDropdown"
        class="btn-neutral"
        :disabled="buttonDisabled"
        :class="btnClass"
        @click="toggle"
      >
        <component
          :is="btnIcon"
          v-if="btnIcon"
          :size="18"
        />
        {{ buttonLabel }}
        <component
          :is="suffixBtnIcon"
          v-if="suffixBtnIcon"
          :size="18"
        />
      </EButton>
    </div>

    <div v-if="options.length" ref="floating" :style="floatingStyles" class="z-[100]">
      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="transform scale-100 opacity-0"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <div
          v-show="isOpen"
          class="dropdown-content menu p-2 drop-shadow-lg bg-base-100 rounded-box"
        >
          <!-- Multiple is using checkbox -->
          <ul v-if="multiple">
            <li v-for="option in options" :key="isOptionAnObject(option) ? String(option.value) : String(option)">
              <Checkbox
                :id="String(option.toString)"
                :model-value="isSelected(option)"
                :disabled="isOptionAnObject(option) && option.disabled"
                :label="getOptionLabel(option)"
                @change="handleChange(option)"
              />
            </li>
          </ul>
          <!-- Single is using radio -->
          <ul v-else>
            <li v-for="option in options" :key="isOptionAnObject(option) ? String(option.value) : String(option)">
              <label class="flex items-center">
                <input
                  :model-value="isSelected(option)"
                  :name="`radio-${uuid}`"
                  type="radio"
                  :value="getOptionValue(option)"
                  :checked="isSelected(option)"
                  class="radio radio-neutral radio-xs"
                  @change="handleChange(option)"
                >
                <span class="text-sm font-medium text-base-content">{{ getOptionLabel(option) }}</span>
              </label>
            </li>
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>

import type {
  CollectGraphql,
  CollectPricingSummaryGraphql,
  IncidentGraphql,
  RateCollectNpsInputGraphql,
} from '~/types/graphql-backend-types/gql-types'
import {
  COLLECT_DETAILS_SUMMARY,
  COLLECTED_CONTAINER_DETAILS_SUMMARY,
  COLLECTS_QUERY_SINGLE,
  RATE_COLLECT_NPS,
} from '~/queries/collects'
import { isObjectEmpty } from '~/utils/object'

export const useCollectStore = defineStore('collect', () => {
  const collect = ref<CollectGraphql>({} as CollectGraphql)
  const collectLoading = ref<boolean>(false)
  const { isAdmin } = storeToRefs(useUsersStore())

  const incidents = computed(() => {
    if (isObjectEmpty(collect.value))
      return []
    return collect.value.incidents.collection as IncidentGraphql[]
  })

  const incidentsPenalty = computed(() => {
    let total = 0
    incidents.value.forEach((incident) => {
      if (incident)
        total += incident.penaltyAmount ? incident.penaltyAmount : 0
    })
    return total
  })

  const transportPrice = computed(() => {
    return collect.value?.transportPrice
  })

  async function loadSingleCollect(id: string) {
    if (!id)
      return

    const { query } = useGqlMikro()

    collectLoading.value = true
    const { data } = await query({ query: COLLECTS_QUERY_SINGLE, variables: {
      pagination: {},
      filters: { ids: [id] },
      isAdmin: isAdmin.value,
    } })
    collectLoading.value = false
    collect.value = JSON.parse(JSON.stringify(data.collects.collection[0]))
    collect.value.startsAt = new Date(collect.value.startsAt).toISOString()
    return collect.value
  }

  /**
   * Fetches the payment summary for a collect or a collected container.
   * @param collectOrCollectedContainerId The id of the collect or collected container.
   * @param viewAsProducer If true, the payment summary will be calculated as if the connected user is a producer.
   * @param object The type of object being queried. Can be 'collect' or 'collectedContainer'.
   * @returns The payment summary for the collect or collected container, or undefined if the object does not exist.
   */
  async function loadPaymentSummary(collectOrCollectedContainerId: string, viewAsProducer: boolean, object: 'collect' | 'collectedContainer'): Promise<CollectPricingSummaryGraphql | undefined> {
    const { query } = useGqlMikro()
    const { data } = await query({
      query: object === 'collect' ? COLLECT_DETAILS_SUMMARY : COLLECTED_CONTAINER_DETAILS_SUMMARY,
      variables: {
        pagination: {},
        filters: { ids: [collectOrCollectedContainerId] },
        input: { viewAsProducer },
      },
    })

    if (object === 'collect' && data.collects.collection[0]?.pricingSummary)
      return data.collects.collection[0]?.pricingSummary

    if (object === 'collectedContainer' && data.collectedContainers.collection[0]?.pricingSummary)
      return data.collectedContainers.collection[0]?.pricingSummary

    return undefined
  }

  function addDocumentToCollectedContainer(document: any, collectedContainerId: number) {
    const collectedContainer = collect.value.collectedContainers.collection.find(item => Number(item?.id) === collectedContainerId)
    if (!collectedContainer)
      return
    collectedContainer?.documents.collection.push(document)
  }

  function removeDocumentFromCollectedContainer(documentId: string, collectedContainerId: number) {
    const collectedContainer = collect.value.collectedContainers.collection.find(item => Number(item?.id) === collectedContainerId)
    if (!collectedContainer)
      return
    const documentIndex = collectedContainer.documents.collection.findIndex(item => item?.id === documentId)
    collectedContainer?.documents.collection.splice(documentIndex, 1)
  }

  async function rateCollectNps(input: RateCollectNpsInputGraphql) {
    const { mutate } = useGqlMikro()
    const { collects } = storeToRefs(useCollectsStore())

    const { data, errors } = await mutate({
      mutation: RATE_COLLECT_NPS,
      variables: { input },
    })

    if (errors) {
      throw new Error(errors[0].message)
    }

    const cs = collects.value?.collection?.find(item => item?.id === input.collectId)
    if (cs) {
      cs.npsRating = data!.rateCollectNPS.npsRating
    }
    collect.value!.npsRating = data!.rateCollectNPS.npsRating

    return { data }
  }

  return {
    collect,
    collectLoading,
    incidents,
    incidentsPenalty,
    transportPrice,
    rateCollectNps,
    loadPaymentSummary,
    loadSingleCollect,
    addDocumentToCollectedContainer,
    removeDocumentFromCollectedContainer,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCollectStore, import.meta.hot))
}

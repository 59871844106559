<script setup lang="ts">
import type { CollectedContainerGraphql, CollectGraphql, Maybe } from '~/types/graphql-backend-types/gql-types'
import { BanknoteIcon, PencilIcon, ReceiptEuroIcon } from 'lucide-vue-next'

const router = useRouter()

const { t } = useI18n()
const { collects, filters, collectsLoading } = storeToRefs(useCollectsStore())
const { loadBOCollects } = useCollectsStore()
const { selectedClientId } = storeToRefs(useClientsStore())

const columns = [
  { key: 'id', label: t('global.id') },
  { key: 'invoice', label: t('collect.invoiceable') },
  { key: 'startsAt', label: t('global.pickup_date'), tdClass: 'whitespace-nowrap' },
  { key: 'client', label: t('global.producer'), tdClass: 'whitespace-nowrap' },
  { key: 'recycler', label: t('global.recycler'), tdClass: 'whitespace-nowrap' },
  { key: 'status', label: t('global.status') },
  { key: 'material', label: t('global.materials') },
  { key: 'containers', label: t('global.containers'), tdClass: 'whitespace-nowrap' },
  { key: 'createdAt', label: t('global.created_at'), tdClass: 'whitespace-nowrap' },
]

function goToCollect(id: any) {
  const to = id || 'new'
  router.push(`/collects/${to}`)
}

watch(selectedClientId, () => {
  loadBOCollects()
})

function groupMaterialByName(collection: Maybe<CollectedContainerGraphql>[]): string[] {
  if (!collection)
    return []
  const materialMap = new Map<string, number>()

  collection.forEach((container) => {
    if (container) {
      const material = container.material?.name
      if (material) {
        materialMap.set(material, (materialMap.get(material) || 0) + 1)
      }
    }
  })
  return Array.from(materialMap.entries()).map(([material, quantity]) => `${material} x${quantity}`)
}

function groupContainerByName(collection: Maybe<CollectedContainerGraphql>[]): string[] {
  if (!collection)
    return []
  const containerMap = new Map<string, number>()

  collection.forEach((c) => {
    if (c) {
      const container = c.container?.name
      if (container) {
        containerMap.set(container, (containerMap.get(container) || 0) + 1)
      }
    }
  })
  return Array.from(containerMap.entries()).map(([container, quantity]) => `${container} x${quantity}`)
}

function editCollect(id = '') {
  if (!id)
    return
  goToCollect(id)
}

onMounted(async () => {
  loadBOCollects()
})
</script>

<template>
  <section class="w-full flex flex-col gap-4 rounded-md p-4 md:p-6">
    <div class="flex items-center gap-2">
      <RecollectionSortingPopOver v-model="filters.sort" />
      <RecollectionStatusPopOver v-model="filters.statuses" />
    </div>
    <ETable
      v-if="!collectsLoading"
      :columns="columns"
      :rows="collects.collection"
      tr-class="cursor-pointer"
      @row-clicked="editCollect($event.id)"
    >
      <template #cell-id="{ row }: { row: CollectGraphql }">
        <div class="flex items-center gap-2">
          {{ row.id }}
        </div>
      </template>

      <template #cell-invoice="{ row }: { row: CollectGraphql }">
        <div v-if="row.isInvoiceable?.errors?.length > 0" class="text-error">
          {{ row.isInvoiceable.errors.length }}
        </div>
        <div v-else class="text-success">
          <BanknoteIcon v-if="row.isInvoiceable.alreadyInvoiced" :size="20" class="text-success" />
          <ReceiptEuroIcon v-else :size="20" class="text-base-content" />
        </div>
      </template>

      <template #cell-status="{ row }: { row: CollectGraphql }">
        <!-- <Tag :tag="formatText(row[col.key])" /> -->
        <Badge color="accent">
          {{ t(`collect.status_${row.status}`) }}
        </Badge>
      </template>

      <template #cell-startsAt="{ row }: { row: CollectGraphql }">
        {{
          new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
            timeZone: "UTC",
          }).format(new Date(row.startsAt))
        }}
      </template>

      <template #cell-transportPrice="{ row }: { row: CollectGraphql }">
        {{ row.transportPrice }} €
      </template>

      <template #cell-client="{ row }: { row: CollectGraphql }">
        {{ row?.client?.name }}
      </template>

      <template #cell-createdAt="{ row }: { row: CollectGraphql }">
        {{
          new Date(row.createdAt).toLocaleDateString("fr-FR", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })
        }}
      </template>

      <template #cell-recycler="{ row }: { row: CollectGraphql }">
        <p v-if="row.recycler">
          {{ row?.recycler?.name }}
        </p>
        <p v-else class="text-zinc-500 italic">
          {{ t("global.no_recycler_defined_for_the_moment") }}
        </p>
      </template>

      <template #cell-containers="{ row }: { row: CollectGraphql }">
        <div class="flex flex-col gap-2">
          <div v-for="container in groupContainerByName(row.collectedContainers.collection)" :key="container">
            <Badge color="neutral">
              {{ container }}
            </Badge>
          </div>
          <div v-if="row.collectedContainers.collection.length === 0">
            <p class="text-gray-400 italic">
              {{ t("global.no_container_for_the_moment") }}
            </p>
          </div>
        </div>
      </template>

      <template #cell-material="{ row }: { row: CollectGraphql }">
        <div v-if="row.collectedContainers.collection.length > 0" class="flex flex-col gap-2">
          <div v-for="material in groupMaterialByName(row.collectedContainers.collection)" :key="material">
            <Badge v-if="material" color="neutral">
              {{ material }}
            </Badge>
          </div>
        </div>
        <div v-else>
          <p class="text-gray-400 italic">
            {{ t("global.no_material_for_the_moment") }}
          </p>
        </div>
      </template>

      <template #row-actions="{ row }">
        <div class="flex flex-row justify-end">
          <EButton
            class="btn-square btn-neutral"
            :icon="PencilIcon"
            @click="editCollect(row?.id)"
          />
        </div>
      </template>
    </ETable>
    <Loader v-else />
  </section>
</template>

import { i18n } from '~/modules/i18n'

const t = i18n.global.t

export async function loadStores() {
  const { loadUsersNest, getCurrentUser } = useUsersStore()
  const { stores_loaded, stores_loading_msg } = storeToRefs(useUsersStore())

  stores_loaded.value = false
  await loadUsersNest({})
  stores_loading_msg.value = t('stores.users_loading')
  await getCurrentUser()
  stores_loading_msg.value = t('stores.data_loading')
  stores_loaded.value = true
}

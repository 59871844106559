<script setup lang="ts">
import type { ModelRef } from 'vue'
import type { CollectStatus } from '~/types/graphql-backend-types/gql-types'
import {
  ChevronDownIcon,
  ListFilterIcon,
} from 'lucide-vue-next'

const status: ModelRef<CollectStatus[]> = defineModel() as ModelRef<CollectStatus[]>
const { t } = useI18n()
const { statusDefinitions } = useCollect()

function addStatusToFilteredStatuses(s: CollectStatus) {
  if (status.value.includes(s)) {
    status.value = status.value.filter(st => st !== s)
    return
  }
  status.value.push(s)
}
</script>

<template>
  <EDropdown
    v-model="status"
    :button-label="t('global.status')"
    :btn-icon="ListFilterIcon"
    :suffix-btn-icon="ChevronDownIcon"
    :multiple="true"
    :options="statusDefinitions"
    @change="addStatusToFilteredStatuses($event as CollectStatus)"
  />
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { colorMap } from '~/utils/badge'
import { CollectStatus } from '~/types/graphql-backend-types/gql-types'

const props = defineProps({
  status: {
    type: String as PropType<CollectStatus>,
    required: true,
  },
  color: {
    type: String as PropType<keyof typeof colorMap>,
    required: false,
    default: 'accent',
  },
})

const { t } = useI18n()
const { status } = toRefs(props)
const { isRecycler, isProducer, isAdmin } = storeToRefs(useUsersStore())
const { getStatusDefinition } = useCollect()

const statusLabel = computed(() => {
  if (isAdmin.value)
    return `${t(`collect.status_${status.value}`)}`

  if (isProducer.value || isRecycler.value) {
    if (status.value === CollectStatus.Invoicing || status.value === CollectStatus.AdminInProgress)
      return `${t(`collect.status_completed`)}`

    return `${t(`collect.status_${status.value}`)}`
  }

  return `$${t(`collect.status_${status.value}`)}`
})
</script>

<template>
  <Tooltip :text="getStatusDefinition(status)">
    <Badge :color="color">
      {{ statusLabel }}
    </Badge>
  </Tooltip>
</template>

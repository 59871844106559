import gql from 'graphql-tag'

export const COLLECT_DETAILS_SUMMARY_FRAGMENT = gql`
  fragment CollectPricingSummaryGraphql on CollectGraphql {
    pricingSummary(input: $input) {
      ready
      summary {
        incidentsTotalWithTax
        incidentsTotalWithoutTax
        totalTax
        totalWithTax
        totalWithoutTax
        transportTotalWithTax
        transportTotalWithoutTax
        totalTreatmentWithTax
        totalTreatmentWithoutTax
        totalResaleWithoutTax
        totalResaleWithTax
      }
    }
  }
`

<script setup lang="ts">
import type { Placement } from '@floating-ui/vue'
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from '@floating-ui/vue'

const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  placement: {
    type: String as PropType<Placement>,
    default: 'top',
  },
})

const triggerRef = ref<HTMLElement | null>(null)
const tooltipRef = ref<HTMLElement | null>(null)

const middleware = ref([
  offset(8),
  flip(),
  shift({ padding: 5 }),
])

const { floatingStyles } = useFloating(triggerRef, tooltipRef, {
  placement: props.placement,
  middleware,
  whileElementsMounted: autoUpdate,
})
</script>

<template>
  <div class="group/tooltip relative inline-block">
    <div ref="triggerRef">
      <slot />
    </div>

    <div
      ref="tooltipRef"
      :style="floatingStyles"
      class="invisible opacity-0 group-hover/tooltip:visible group-hover/tooltip:opacity-100
        transition-opacity duration-200 fixed
        text-neutral-content text-sm rounded whitespace-nowrap z-[200]"
      :class="{ 'px-2 py-1 bg-neutral': text }"
    >
      <slot name="tooltip-content">
        {{ text }}
      </slot>
    </div>
  </div>
</template>

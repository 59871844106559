<script setup lang="ts">
import { computed } from 'vue'
import { colorMap } from '~/utils/badge'

const props = defineProps({
  color: {
    type: String as PropType<keyof typeof colorMap>,
    validator: (value: string) => {
      return ['primary', 'secondary', 'neutral', 'accent', 'success', 'error', 'info', 'warning'].includes(value)
    },
  },
  to: {
    type: String,
  },
})

const router = useRouter()

const backgroundColor = computed(() => {
  return props.color ? colorMap[props.color].bg : ''
})

const textColor = computed(() => {
  return props.color ? colorMap[props.color].text : ''
})

function to() {
  if (props.to) {
    router.push(props.to)
  }
}
</script>

<template>
  <span
    class="inline-flex items-center justify-center px-2.5 py-0.5 rounded-full text-xs font-medium w-fit h-fit whitespace-nowrap"
    :class="[
      backgroundColor,
      textColor,
      $attrs.class,
    ]"
    @click.stop.prevent="to"
  >
    <slot />
  </span>
</template>

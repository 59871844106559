export const colorMap = {
  primary: {
    bg: 'bg-primary',
    text: 'text-primary-content',
  },
  secondary: {
    bg: 'bg-secondary',
    text: 'text-secondary-content',
  },
  neutral: {
    bg: 'bg-neutral',
    text: 'text-neutral-content',
  },
  accent: {
    bg: 'bg-accent',
    text: 'text-accent-content',
  },
  success: {
    bg: 'bg-success',
    text: 'text-success-content',
  },
  error: {
    bg: 'bg-error',
    text: 'text-error-content',
  },
  info: {
    bg: 'bg-info',
    text: 'text-info-content',
  },
  warning: {
    bg: 'bg-warning',
    text: 'text-warning-content',
  },
}

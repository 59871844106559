import { CollectStatus } from '~/types/graphql-backend-types/gql-types'

export function useCollect() {
  const { isAdmin, isProducer, isRecycler } = storeToRefs(useUsersStore())
  const { t } = useI18n()

  const statusDefinitions = computed(() => [
    { value: CollectStatus.RequestSent, comment: t('global.awaiting_recycler_validation'), label: t('global.request_sent') },
    { value: CollectStatus.Confirmed, comment: t('global.upcoming service'), label: t('collect.status_confirmed') },
    { value: CollectStatus.AdminInProgress, comment: isAdmin.value ? t('global.documentation_being_finalized') : t('collect.collect_completed'), label: t('collect.status_completed') },
    { value: CollectStatus.HasIncident, comment: t('global.problems_with_the_service'), label: t('collect.status_hasIncident') },
    { value: CollectStatus.Finished, comment: t('collect.collect_validated_and_closed'), label: t('collect.status_finished') },
    { value: CollectStatus.Cancelled, comment: t('collect.collect_cancelled_and_not_completed'), label: t('collect.status_cancelled') },
    ...(isAdmin.value ? [{ value: CollectStatus.Invoicing, comment: t('collect.status_invoicing'), label: t('collect.status_invoicing') }] : []),
  ])

  function availableStatuses(oldStatus: CollectStatus) {
    const availableStatuses: { value: CollectStatus, label: string, disabled?: boolean }[] = []

    if (isAdmin.value) {
      availableStatuses.push(
        { value: CollectStatus.RequestSent, label: t('global.request_sent') },
        { value: CollectStatus.Confirmed, label: t('collect.status_confirmed') },
        { value: CollectStatus.AdminInProgress, label: t('collect.status_completed') },
        { value: CollectStatus.HasIncident, label: t('collect.status_hasIncident') },
        { value: CollectStatus.Finished, label: t('collect.status_finished') },
        { value: CollectStatus.Cancelled, label: t('collect.status_cancelled') },
        { value: CollectStatus.Invoicing, label: t('collect.status_invoicing') },
      )
    }
    else if (isProducer.value) {
      switch (oldStatus) {
        case CollectStatus.RequestSent:
          availableStatuses.push(
            { value: CollectStatus.HasIncident, label: t('collect.status_hasIncident') },
            { value: CollectStatus.Cancelled, label: t('collect.status_cancelled') },
          )
          break
        case CollectStatus.Confirmed:
          availableStatuses.push({ value: CollectStatus.HasIncident, label: t('collect.status_hasIncident') })
          break
      }
    }
    else if (isRecycler.value) {
      switch (oldStatus) {
        case CollectStatus.RequestSent:
          availableStatuses.push(
            { value: CollectStatus.HasIncident, label: t('collect.status_hasIncident') },
            { value: CollectStatus.Cancelled, label: t('collect.status_cancelled') },
            { value: CollectStatus.Confirmed, label: t('collect.status_confirmed') },
          )
          break
        case CollectStatus.Confirmed:
          availableStatuses.push(
            { value: CollectStatus.HasIncident, label: t('collect.status_hasIncident') },
            { value: CollectStatus.Cancelled, label: t('collect.status_cancelled') },
            { value: CollectStatus.AdminInProgress, label: t('collect.status_completed') },
          )
          break
      }
    }

    return availableStatuses
  }

  function getStatusDefinition(status: CollectStatus) {
    return statusDefinitions.value.find(s => s.value === status)?.comment
  }

  return { availableStatuses, getStatusDefinition, statusDefinitions }
}
